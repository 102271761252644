import fetch from "isomorphic-fetch";
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

// const authLink = setContext((_, { headers }) => {
//   const data = sessionStorage.getItem("jwt");
//   const token = data.token;

//   return {
//     headers: {
//       ...headers,
//       Authorization: token ? `Bearer ${token}` : "",
//     },
//   };
// });

export const client = new ApolloClient({
  link: new HttpLink({
    uri: "https://api-whitlockite-ls48.antikode.dev/graphql",
    fetch,
    fetchOptions: {
      "Access-Control-Allow-Origin": "*",
    },
  }),
  cache: new InMemoryCache(),
});
