import React from "react";
import { ApolloProvider } from "@apollo/client";

import { client } from "./src/apollo/client";
import { AuthProvider } from "./src/context/auth";

import "./src/assets/scss/main.scss";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <AuthProvider>{element}</AuthProvider>
  </ApolloProvider>
);
