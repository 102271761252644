import React, { createContext, useState } from "react";
import { isLoggedIn } from "../components/anti/utils/auth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [info, setInfo] = useState(() =>
    isLoggedIn() ? isLoggedIn() : { name: null, token: null, role: null }
  );

  return (
    <AuthContext.Provider value={{ info, setInfo }}>
      {children}
    </AuthContext.Provider>
  );
};
